import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Toaster } from 'react-hot-toast'
const Layout = ({ children }) => {
    return (
        <div className='wrap'>
            <Header />
            <div>
                <Toaster />
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout